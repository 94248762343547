import React, { useEffect, useState, useRef } from 'react'
import { Grid, Typography, Link, Pagination } from '@mui/material';
import { Card, CardContent, CardMedia } from '@mui/material';
import { Box } from '@mui/system';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import Loading from './../components/Loading';
import { url, dateStr } from './../functions.js'

export default function News() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const myRef = useRef();
  const { p } = useParams();
  let navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (event, value) => {
    if (value !== page) {
      navigate("/news/"+value);
    }
  };

  useEffect(() => {
    let pNum = parseInt(p);
    if (isNaN(pNum) || pNum === 0) {
      pNum = 1;
    }
    setIsLoading(true);
    setPage(pNum);
    fetch(url('/s/news?page='+pNum))
    .then(res => res.json())
    .then(json => {
      setData(json.Data);
      setPages(json.Pages);
      if (!isNaN(p)) {
        myRef.current.scrollIntoView();
      }
      setIsLoading(false);
    });
  }, [p]);
 
  return (
    <div>
      <Helmet>
        <title>News - ontons.com</title>
        <meta name="description" content="Cryptocurrency news and tools" />
      </Helmet>
      <Loading show={isLoading} />

      <Grid container ref={myRef} spacing={1} alignItems="stretch">
        {data?.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.url}>
          
          <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              { post.ImgBase64 && 
              <CardMedia
                  component="img"
                  height="140"
                  image={post.ImgBase64}
                />
              }
              <CardContent>
              
                <Typography variant="h6" component="div">
                  {post.title}
                </Typography>

                <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
                  {moment(post.Time).fromNow()}
                </Typography>
              
                <Typography component="div" mt={1} variant="body2">
                    {post.summary}
                </Typography>

                <Typography component="div" mt={1} variant="body2" sx={{ color: 'text.secondary' }}>
                  Source: <Link href={post.url} target="_blank" sx={{ color: 'text.secondary' }}>{post.source_domain}</Link>
                </Typography>
                
              </CardContent>
            </Card>

          </Grid>
        ))}
      </Grid>

      <Box my={1}>
        <Pagination count={pages} page={page} onChange={handleChange} color="primary" />
      </Box>
    </div>
  )
}