import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Chip, Typography, Stack } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from "react-router-dom";
import Markdown from 'react-markdown'

import Loading from '../../components/Loading.js';
import { url, wikipediaUrl, extUrlStock } from '../../functions.js'
import TradingViewWidget from '../../components/TradingViewWidget';

export default function Stock(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const {symbol} = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    fetch(url('/s/stock/'+symbol))
    .then(
      res => res.json())
    .then(json => {
      if (!json.Symbol) {
        navigate(-1);
      }
      setData(json);
      setIsLoading(false);
    })
  },[]);

  return (
    <Box>

      <Helmet>
        <title>{ `(${ data.Symbol }) ${ data.Name }` } - ontons.com</title>
        <meta name="description" content="{ `(${ data.Symbol }) ${ data.Name }` }, overview, information, stock data" />
      </Helmet>
      <Loading show={isLoading} />

      <Card>
        <CardContent>
            {!isLoading && <Typography variant="h5" component="div">
                <b>({data.Symbol})</b> {data.Name}
            </Typography>}
           
            <Box sx={{ mb: 1}}>
              <Box component="div" sx={{ display: 'inline', color: 'text.secondary', mr: 1 }}>{data.Sector} - {data.SubIndustry}</Box>
              <Chip label="TradingView" size="small" color="secondary" component="a" target="_blank" sx={{ mr: 1 }} clickable 
                    icon={<LinkIcon />} 
                    href={extUrlStock('tradingview', data.Symbol, data.Exchange)}/>
              <Chip label="Wikipedia" size="small" color="secondary" component="a" target="_blank" sx={{ mr: 1 }} clickable 
                    icon={<LinkIcon />} 
                    href={wikipediaUrl(data.WikipediaLink)}/>
              <Chip label="GoogleFinance" size="small" color="secondary" component="a" target="_blank" sx={{ mr: 1 }} clickable 
                    icon={<LinkIcon />} 
                    href={extUrlStock('google', data.Symbol, data.Exchange)}/>
              <Chip label="YahooFinance" size="small" color="secondary" component="a" target="_blank" sx={{ mr: 1 }} clickable 
                    icon={<LinkIcon />} 
                    href={extUrlStock('yahoo', data.Symbol, data.Exchange)}/>
              <Chip label="MarketWatch" size="small" color="secondary" component="a" target="_blank" sx={{ mr: 1 }} clickable 
                    icon={<LinkIcon />} 
                    href={extUrlStock('marketwatch', data.Symbol, data.Exchange)}/>
            </Box>

            { !isLoading && 
              <Box height={300}><TradingViewWidget symbol={data.Symbol} exch={data.Exchange}/></Box>
            }

            <Typography component="div" variant="body2">
                <Markdown>{data.Overview}</Markdown>
            </Typography>
        </CardContent>     
      </Card>

    </Box>
  )
}