import React, { useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, Typography, TableRow, TextField, Toolbar, Alert } from '@mui/material'
import { Paper, Link, IconButton } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh';
import { Helmet } from 'react-helmet';

import Loading from './../components/Loading';
import { url, exchUrl } from './../functions.js'

export default function News() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(true);

  var countDecimals = (pair, value) => {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
      let [base, trail] = text.split('e-');
      let deg = parseInt(trail, 10);
      deg = deg + Math.max(base.length - 2, 0);
  
      return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
      return value.toString().split(".")[1].length || 0;
    }
    return 0;
  }

  const handleFilter = (value) => {
    setFilter(value)
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(url('/s/arbitrage'))
    .then(
      res => res.json())
    .then(json => {
      //json.Data?.sort((a,b) => b.PriceDelta - a.PriceDelta)
      setData(json.Data);
      setIsLoading(false);
    })
  },[refresh]);

  useEffect(() => {
    if (filter && filter !== " ") {
        var tmp = data.filter((element) => {
            var ret = false
            ret = ret || element["Symbol"].toLowerCase().includes(filter.toLowerCase())
            ret = ret || element["Exchange1"].toLowerCase().includes(filter.toLowerCase())
            ret = ret || element["Exchange1"].toLowerCase().includes(filter.toLowerCase())
            return ret
        })
        setFilteredData(tmp)
    } else {
        setFilteredData(data)
    }
  },[data, filter]);

  return (
    <Box>
      <Helmet>
        <title>Arbitrage - ontons.com</title>
        <meta name="description" content="Cryptocurrency arbitrage scanner, screener, watcher" />
      </Helmet>
      <Loading show={isLoading} />

      <Typography variant="body1" sx={{ mt: 1, mb: 1}}>  
      Price data is gathered from: Binance, Bybit, KuCoin, Okx. <br/>
      </Typography> 

        <Alert severity="warning" variant="outlined" sx={{ mt: 2 }}>
        Before making any trades please make sure that withdrawal/deposit is enabled for selected asset (there might be maintenance on the network or exchange). 
        Also take in consideration trading volume(might be not enough liquidity), trading/withdrawal fees and high volatility. 
        </Alert>

        

      <Paper elevation={3} sx={{ width: '100%', mb: 2, mt: 2 }}>
      <TableContainer>
      <Toolbar>
        <TextField 
            id="outlined-search" 
            label="Search" 
            type="search" 
            size="small" 
            value={filter}
            onChange={(e) => {handleFilter(e.target.value)}}
            />
        <IconButton 
              sx={{ ml: 1 }}
              color="primary" 
              component="label"
              onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Toolbar>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Symbol</TableCell>
            <TableCell>Price 1</TableCell>
            <TableCell>Price 2</TableCell>
            <TableCell align="right">Difference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.map((row) => (
            <TableRow
              hover
              key={row.Id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.Symbol}</TableCell>
              <TableCell>
                <Link  href={exchUrl(row.Exchange1, row.BaseAsset1, row.QuoteAsset1)} 
                                        target="_blank" 
                                        sx={{ color: 'red' }}>
                  {row.Exchange1}: {parseFloat(row.Price1).toFixed(countDecimals(row.BaseAsset1+row.QuoteAsset1, row.Price1))}</Link>
              </TableCell>
              <TableCell>
                <Link  href={exchUrl(row.Exchange2, row.BaseAsset2, row.QuoteAsset2)} 
                                        target="_blank">
                  {row.Exchange2}: {parseFloat(row.Price2).toFixed(countDecimals(row.BaseAsset2+row.QuoteAsset2,row.Price2))}</Link>
              </TableCell>
              <TableCell align="right">{Math.abs(parseFloat(row.PriceDelta).toFixed(2))}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
    </Box>
  )
}