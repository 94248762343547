import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import GoogleSignIn from './GoogleSignIn.js'
import UserContext from "../contexts/UserContext";
import { url } from "../functions.js"

const pages = [
  { title: 'News', pathname: 'news', key: 'news' },
  { title: 'Events', pathname: 'events', key: 'events' },
  { title: 'Monitor', pathname: 'monitor', key: 'monitor' },
  { title: 'Arbitrage', pathname: 'arbitrage', key: 'arbitrage' },
  { title: 'Alerts', pathname: 'alerts', key: 'alerts' },
 
];
const logoText = '';

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElStocks, setAnchorElStocks] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenStocksMenu = (event) => {
    setAnchorElStocks(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseStocksMenu = () => {
    setAnchorElStocks(null);
    setAnchorElNav(null);
  };

  const { user, setUser } = useContext(UserContext);
  const handleLogout = () => {
    setAnchorElUser(null);
    fetch(url('/s/signout'), {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then(json => setUser(json));
  };

  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Box
              component="img"
              sx={{
                display: { xs: 'none', md: 'flex' },
                height: 68
              }}
              alt="Logo"
              src="/logo.png"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.key} onClick={handleCloseNavMenu} component={Link} to={page}>
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
              <MenuItem component={Link} onClick={handleOpenStocksMenu}>
                <Typography textAlign="center">Stocks</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            component="img"
            sx={{
              display: { xs: 'flex', md: 'none' },
              height: 50
            }}
            alt="Logo"
            src="/logo.png"
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {logoText}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.key}
                component={Link} to={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'inherit', display: 'block' }}
                >
                  {page.title}
              </Button>
            ))}
            <Button
              component={Link} 
              onClick={handleOpenStocksMenu}
              sx={{ my: 2, color: 'inherit', display: 'block' }}
              >
                Stocks
            </Button>
          </Box>

          <Menu
            id="stocks-menu"
            anchorEl={anchorElStocks}
            open={Boolean(anchorElStocks)}
            onClose={handleCloseStocksMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleCloseStocksMenu} component={Link} to="stocks/low-volatility-snp500">S&P 500: Low volatility and price</MenuItem>
            <MenuItem onClick={handleCloseStocksMenu} component={Link} to="stocks/overbought-oversold-snp500">S&P 500: Overbought/Oversold</MenuItem>
            
          </Menu>

          {user.LoggedIn && 
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.GivenName} src={user.Picture} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Sign out</Typography>
              </MenuItem>
            </Menu>
          </Box>
          }
          {!user.LoggedIn &&
            <Box>
              <GoogleSignIn />
            </Box>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
