import React, { useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, Typography, TableRow, TextField, Toolbar } from '@mui/material'
import { Paper, Link } from '@mui/material'
import { Helmet } from 'react-helmet';

import Loading from './../../components/Loading';
import { url, stockUrl } from './../../functions.js'

export default function News() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleFilter = (value) => {
    setFilter(value)
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(url('/s/stocks/lowvolatility'))
    .then(
      res => res.json())
    .then(json => {
      //json.Data?.sort((a,b) => b.PriceDelta - a.PriceDelta)
      setData(json);
      setIsLoading(false);
    })
  },[]);

  useEffect(() => {
    if (filter && filter !== " ") {
        var tmp = data.Data.filter((element) => {
            var ret = false
            ret = ret || element["Symbol"].toLowerCase().includes(filter.toLowerCase())
            ret = ret || element["Name"].toLowerCase().includes(filter.toLowerCase())
            return ret
        })
        setFilteredData(tmp)
    } else {
        setFilteredData(data.Data)
    }
  },[data.Data, filter]);

  return (
    <Box>
      <Helmet>
        <title>Low volatility low price S&P500 - ontons.com</title>
        <meta name="description" content="Low volatility low price S&P500 scanner, screener, watcher, investment idea" />
      </Helmet>
      <Loading show={isLoading} />

      <Typography component="div" variant="h4" sx={{ mt: 1, mb: 1}}>Low volatility low price S&P500 stocks</Typography> 

      <Typography component="div" variant="body1" sx={{ mt: 1, mb: 1}}>
      List of S&P500 stocks that have least volatility and lowest possible price based on one year daily data. Updated daily.<br/>
      Can be useful for swing trading or short term investment. This is not a financial advice, please always do your own research.
      </Typography> 

      <Paper elevation={3} sx={{ width: '100%', mb: 2, mt: 2 }}>
      <TableContainer>
      <Toolbar>
        <TextField 
            id="outlined-search" 
            label="Search" 
            type="search" 
            size="small" 
            value={filter}
            onChange={(e) => {handleFilter(e.target.value)}}
            />
      </Toolbar>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Symbol</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Min</TableCell>
            <TableCell align="right">Max</TableCell>
            <TableCell align="right">Latest</TableCell>
            <TableCell align="right">Latest change</TableCell>
            <TableCell align="right">Median</TableCell>
            <TableCell align="right">Volatility</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.map((row) => (
            <TableRow hover key={row.Symbol} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell><Link  href={stockUrl(row.Symbol)}>{row.Symbol}</Link></TableCell>
              <TableCell><Link  href={stockUrl(row.Symbol)}>{row.Name}</Link></TableCell>
              <TableCell align="right">{Math.abs(parseFloat(row.Minimum).toFixed(2))}$</TableCell>
              <TableCell align="right">{Math.abs(parseFloat(row.Maximum).toFixed(2))}$</TableCell>
              <TableCell align="right">{Math.abs(parseFloat(row.Latest).toFixed(2))}$</TableCell>
              <TableCell align="right">{Math.abs(parseFloat(row.Latest_percent).toFixed(2))}%</TableCell>
              <TableCell align="right">{Math.abs(parseFloat(row.Median).toFixed(2))}</TableCell>
              <TableCell align="right">{Math.abs(parseFloat(row.Volatility_percent).toFixed(2))}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
    </Box>
  )
}