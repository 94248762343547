import React, { useEffect, useState } from 'react'
import { Grid, Typography, Card, CardContent, Link, CardActions, Button } from '@mui/material';
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Chip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';
import Markdown from 'react-markdown'
import moment from 'moment';

import Loading from './../components/Loading';
import { url } from './../functions.js'

export default function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataEvents, setDataEvents] = useState([]);
   
    useEffect(() => {
      setIsLoading(true);
      fetch(url('/s/news/recap'))
      .then(
        res => res.json())
      .then(json => {
        setData(json);
        setIsLoading(false);
      });
      fetch(url('/s/events/home'))
      .then(res => res.json())
      .then(json => {
        setDataEvents(json);
        setIsLoading(false);
      });
    },[]);
  
    return (
      <Box>
  
        <Helmet>
          <title>ontons.com</title>
          <meta name="description" content="news, crypto, summary, recap, ai" />
        </Helmet>
        <Loading show={isLoading} />

        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Card>
              <Typography component="div" variant="h4" ml={2} mt={2}>Latest news recap</Typography>
              <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }} ml={2}>
              Updated: {moment(data.Time).fromNow()}
              </Typography>
              <CardContent>
                <Typography component="div" variant="body2">
                    <Markdown>{data.Text}</Markdown>
                </Typography>
              </CardContent>   

              <CardActions>
                <Button size="small" color="primary" href="news">
                  All news...
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
                <Typography component="div" variant="h4" ml={2} mt={2}>Crypto events</Typography>
                <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }} ml={2}>
                  Updated: {moment(dataEvents.Time).fromNow()}
                </Typography>
                {dataEvents?.Data?.map((event, i) => (
                  <List key={event.ID}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt={event.CoinName} src={event.CoinImg} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={event.CoinName + " (" + event.CoinSymbol +")" + " - " + event.Title}
                          secondaryTypographyProps={{component:"div"}}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="div"
                                variant="body2"
                                color="text.primary"
                              >
                              
                              </Typography>
                              {event.Content} 
                              <Box sx={{ fontWeight: 'light', mt: 2 }}>
                                <Chip 
                                  size="small" 
                                  color="secondary"
                                  label="Source"
                                  component="a" 
                                  target="_blank"
                                  icon={<LinkIcon />}
                                  href={event.Source}
                                  clickable
                                />
                              
                              </Box>
                              
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      { i + 1 < dataEvents.Data.length && <Divider variant="inset" component="li" />}
                  </List>
                ))}
                
                <CardActions>
                  <Button size="small" color="primary" href="events">
                    All events...
                  </Button>
                </CardActions>
            </Card>
          </Grid>

        </Grid>
      </Box>
    )
}