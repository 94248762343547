import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget(props) {
  const container = useRef();

  useEffect(
    () => {
      if (props.symbol) {
        const id = "tradingviewwidget";
        if (container.current) {
          var el = container.current.querySelector("#"+id);
          if (el) {
            el.remove();
          }
        }
       
        const script = document.createElement("script");
        script.setAttribute("id", id);
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
          {
            "autosize": true,
            "symbol": "${props.exch}:${props.symbol}",
            "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "hide_legend": true,
          "allow_symbol_change": true,
          "save_image": false,
          "calendar": false,
          "hide_volume": true,
          "support_host": "https://www.tradingview.com"
          }`;
        container.current.appendChild(script);
      }
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      <div className="tradingview-widget-copyright"></div>
    </div>
  );
}

export default memo(TradingViewWidget);